<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar
            height="40"
            dense
            flat
            color="blue"
            dark
          >
            <h3>Realizar priorizacion al cliente</h3>
          </v-toolbar>
          <v-col>
            <v-select
              label="Tipo de tratamiento"
              dense
            />
            <v-select
              label="Frecuencia"
              dense
            />
          </v-col>
          <v-row
            justify="center"
            class="mx-1"
          >
            <v-col cols="6">
              <v-select
                label="Dia"
                dense
              />
            </v-col>
            <v-col cols="6">
              <v-select
                label="Hora"
                dense
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      proyectos: [],
      headers: [

        { text: 'Fecha', value: 'fecha'},
        { text: 'No.', value: 'numero' },
        { text: 'Importe', value: 'importe' },
        { text: 'Estatus', value: 'estatus' },
  
      ],


      
      // json_fields: {
      //   Estatus: "completo",
      //   Id: "idproyecto",
      //   FechaInicio: "inicio",
      //   FechaFin: "meta",

      //   NombreProyecto: "nomproy",
      //   NumCliente: "numcli",
      //   NumProyecto: "numproy",
      //   Observaciones: "obs",
      //   Precio: "precio",
      // },
      // json_meta: [
      //   [
      //     {
      //       key: "charset",
      //       value: "utf-8",
      //     },
      //   ],
      // ],
      // json_data: [],
    }
  },
}
</script>